import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import { db, auth, teachersCollection, institutesCollection, studentsCollection, lessonAccountsCollection } from '../firebase';
import {getInsituteSettings} from '@/assets/js/dataUtils.js';


Vue.use(Vuex)


const store = new Vuex.Store({
  strict: false,
  state: {
    isLoggedIn: false,
    isAdmin: false,
    prefillAddEvent: {
      date: new Date().toISOString().substr(0,10),
      start: '',
      end: '',
      teacher: {},
      student: '',
      subject: '',
      year: '',
      school: '',
      type: 'event',
    },
  },
  mutations: {

    setIsLoggedIn(state, bool) {
      state.isLoggedIn = bool
    },

    setIsAdmin(state, bool) {
      state.isAdmin = bool;
    },

    /**
     * Sets the attributes of prefillAddEvent
     * @param {string} state 
     * @param {object} input of form '{key, value}' that contains the key and the value that key should be set to. 
     * Eg for key: subject, input: Englisch it does: state.prefillAddEvent.subject = English
     */
    setprefillAddEvent(state, input) {
      state.prefillAddEvent[input.key] = input.value
    },

    /**
     * !do not call this. Call resetprefillAddEvent instead!
     * resets the values of refillAddEvent to those initially set in store
     * is used to blank out perviously filled data in view 'addEvent'
     */
     emptyPrefillAddEvent(state){
      
      state.prefillAddEvent = {
        date: new Date().toISOString().substr(0,10),
        start: '',
        end: '',
        teacher: {},
        student: '',
        room: '',
        subject: '',
        year: '',
        school: '',
        type: 'event',
      }

    },
  },

  actions: {

    async resetPrefillAddEvent({ commit }){

      /* reset all values to blanc */
      commit('emptyPrefillAddEvent');

      /* get the first room from the insitute of the user and set it into store */
      //get instituteId from currently logged in user
      const instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

      //get institute settings
      const doc = await institutesCollection.doc(instituteId).get();

      if (doc.exists) {
        var firstRoom = doc.data().rooms[0];
        commit('setprefillAddEvent', {key: 'room', value: firstRoom});
      } else {
        console.log("No such document!");
      }

      /* get the name of the currently logged in teacher */
      const uid = auth.currentUser.uid;
      const userProfileDoc = await teachersCollection.doc(uid).get();
      
      if(userProfileDoc.exists) {

        let userData = userProfileDoc.data();
        // set the logged in teacher in prefill AddEvent 
        let teacherName = userData.firstname + ' ' + userData.lastname;
        let teacherRef = db.doc(teachersCollection.path + '/' + uid);
        commit('setprefillAddEvent', {key: 'teacher', value: {name: teacherName, ref: teacherRef}});

      } else {
        console.log('User-Document does not exist.')
      }

    },

    async logout({ commit }) {
      await fb.auth.signOut()
      
      //mark user as loged out
      commit('setIsLoggedIn' , false);
      commit('setIsAdmin', false);

      router.push('/login')
    },

    async addUserInformationToStore({ commit }, user) {

      //mark user as loged in
      commit('setIsLoggedIn' , true);

      const userProfileDoc = await teachersCollection.doc(user.uid).get();
      
      if(userProfileDoc.exists) {

        let userData = userProfileDoc.data();
        // set the logged in teacher in prefill AddEvent 
        let teacherName = userData.firstname + ' ' + userData.lastname;
        let teacherRef = db.doc(teachersCollection.path + '/' + user.uid);
        commit('setprefillAddEvent', {key: 'teacher', value: {name: teacherName, ref: teacherRef}});

      } else {
        console.log('User-Document does not exist.')
      }

      // get the first room from the insitute of the user and set it into store
      //get instituteId from currently logged in user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;
      const instituteId = claims.instituteId;
      commit('setIsAdmin', claims.admin);

      //get institute settings
      const doc = await institutesCollection.doc(instituteId).get();
      if (doc.exists) {
        var firstRoom = doc.data().rooms[0];
        commit('setprefillAddEvent', {key: 'room', value: firstRoom});
      } else {
        console.log("No such document!");
      }
    },

    async editLessonAccount({commit}, {studentId, minutes, notes, date, subject, teacherName}){

      //Get the institueId of the currently logged in user
      const instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
      //Get the length of a lesson
      const minEventDuration = (await getInsituteSettings()).minEventDuration;
      const lessons = Math.round((parseInt(minutes) / parseInt(minEventDuration)) * 10) / 10; //round to one decimal

      //add to lesson account logs
      await lessonAccountsCollection.add({
        studentId: studentId,
        lessons: lessons,
        notes: notes,
        date: date,
        instituteId: instituteId,
        subject: subject,
        teacherName: teacherName,
      })

      //get the current balance of the lesson account
      const currentBalance = (await studentsCollection.doc(studentId).get()).data().balanceLessonAccount;

      //change lesson Account in Studentscollection
      await studentsCollection.doc(studentId).update({
        balanceLessonAccount: parseFloat(currentBalance) + parseFloat(lessons),
      })
    }
    
  },
  modules: {
  }
})

export default store
