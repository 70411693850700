import * as firebase from 'firebase/app' 
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'


firebase.initializeApp({
  apiKey: "AIzaSyC-EhmWayyXJTTwGV59mYQHNzsnLeMPJw0",
  authDomain: "alearn-v1.firebaseapp.com",
  projectId: "alearn-v1",
  storageBucket: "alearn-v1.appspot.com",
  messagingSenderId: "548014464883",
  appId: "1:548014464883:web:eb42ac52bc54400c15af06"
});

//utils
const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();

//collections
const institutesCollection = db.collection('institutes');
const teachersCollection = db.collection('teachers');
const eventsCollection = db.collection('events');
const seriesCollection = db.collection('series');
const studentsCollection = db.collection('students');
const lessonAccountsCollection = db.collection('lessonAccounts');

export{
  auth,
  db,
  functions,
  institutesCollection,
  teachersCollection,
  eventsCollection,
  seriesCollection,
  studentsCollection,
  lessonAccountsCollection,
}