<template>
<div>
  <div class="primary">

    <v-container class="py-md-16">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <div class="text-h4 text-sm-h2 text-lg-h1 my-2 mb-4">
            NACHHILFE <br> DIGITAL <br> ORGANISIEREN.
            </div>
          <div class="white--text text-subtitle-1 text-md-h6 my-4">
            <ul>
              <li>einfach zu bedienen</li>
              <li>erspart Zeit</li>
              <li>speziell für kleine Nachhilfeinstitute</li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <v-img 
          src="../../images/mockups/Raumplan_2phones.png"
          contain
          max-width="700px">
          </v-img>
        </v-col>
      </v-row>
      <v-row
      justify="center"
      >
        <v-btn
        dark
        class="my-5"
        href="https://help.alearn.de/desk/form/a7efe596ce6848ddbdd20da3f8890605" target="_blank"
        >
          Frag uns persönlich <v-icon class="ml-3">mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-container>

  </div>

  <div class="accent px-3 px-md-16 pt-16">

    <v-container class="secondary-darken1--text">
      <v-row class="text-h5 text-md-h3 pb-16">
        Mit ALearn organisiert sich dein Nachhilfeinstitut fast von alleine.
      </v-row>
      <v-row class="text-h6 text-md-h4 font-weight-light">
        Alle Nachhilfestunden auf einen Blick.
      </v-row>
      <v-row justify="center">
        <v-img 
          src="../../images/mockups/Mockup Wochenansicht.png"
          contain
          max-height="80vh"
          class="my-4">
        </v-img>
      </v-row>

      <v-row class="text-h6 text-md-h4 font-weight-light mt-16">
        Bitzschnell neue Stunden anlegen.
      </v-row>
      <v-row>
        Natürlich auch mit wöchentlicher Wiederholung.
      </v-row>
      <v-row justify="center">
        <v-img 
          src="../../images/mockups/Mockup AddEvent.png"
          contain
          max-height="80vh"
          class="my-4">
        </v-img>
      </v-row>

      <v-row class="text-h6 text-md-h4 font-weight-light mt-16">
        Automatische Erfassung der Stunden, die ein Schüler gemacht hat.
      </v-row>
      <v-row>
        Und welche davon vertraglich abgedeckt waren.
      </v-row>
      <v-row justify="center">
        <v-img 
          src="../../images/mockups/Mockup Stundenkonto.png"
          contain
          max-height="80vh"
          class="mt-4">
        </v-img>
      </v-row>

    </v-container>

  </div>


  <div class="white primary--text px-3 px-md-16 py-16">

    <v-container>
      <v-row class="text-h5 text-md-h3 pb-16">
        Wir sind für euch da.
      </v-row>


      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="4">
          <v-img 
          src="../../images/animals/whale thumbs up.png"
          max-width="700px">
          </v-img>
        </v-col>
        <v-col cols="12" md="8" class="text-h6 text-md-h4">
          ALearn ist einfach
        </v-col>
      </v-row>

      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="4" order-md="last">
          <v-img 
          src="../../images/animals/whale singing.png"
          max-width="700px">
          </v-img>
        </v-col>
        <v-col cols="12" md="8" class="text-right text-h6 text-md-h4" order-md="first">
          und fügt sich mühelos in euren Alltag ein.
        </v-col>
      </v-row>

      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="4">
          <v-img 
          src="../../images/animals/whale jumping.png"
          max-width="700px">
          </v-img>
        </v-col>
        <v-col cols="12" md="8" class="text-h6 text-md-h4">
          Mit ALearn helfen wir euch einfach und günstig einen großen Schritt 
          bei der Digitalisierung zu machen
        </v-col>
      </v-row>

      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="4" order-md="last">
          <v-img 
          src="../../images/animals/whale with kid.png" 
          max-width="700px">
          </v-img>
        </v-col>
        <v-col cols="12" md="8" class="text-right text-h6 text-md-h4" order-md="first">
          und sind dabei immer an eurer Seite.
        </v-col>
      </v-row>

      <v-row
      justify="center"
      >
        <v-btn
        color="primary"
        class="my-10 white--text"
        block
        href="https://help.alearn.de/desk/form/a7efe596ce6848ddbdd20da3f8890605" target="_blank"
        >
          Jetzt mehr erfahren <v-icon class="ml-3">mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
      
    </v-container>
  
  

  </div>

  <div class="white text-caption mt-16 ma-0 pa-1">
    Die Screenshots zeigen Bilder der echten Software mit Beispieldaten. <br>
    Wir entwickeln ALearn ständig weiter und arbeiten schon an tollen neuen Funktionen für dich! <br>
    Quelle zweites Bild: <a href='https://www.freepik.com/psd/mockup'> freepik</a>
  </div>

</div>
</template>

<script>
export default {
  data(){
    return{
    }
  }
}
</script>

<style scoped>
ul{
  list-style: none;
  padding: 0;
}
ul li:before{
  content: "\279C";
  margin: 0 0.5em;
  color: var(--v-accent-base);
}

</style>