<template>
  <div>

    <v-app-bar
      color="primary"
      class="white--text"
      dense
      flat
      app
    >
        <v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>ALearn</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-icon v-if="isLoggedIn" class="white--text" @click="logout()">mdi-logout</v-icon>
        <v-icon v-else class="white--text" @click="$router.push('/login')">mdi-login</v-icon>
        
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group v-if="isLoggedIn"
          v-model="group"
          active-class="accent">

            <v-list-item :to="{ path: '/raumplan'}">
              <v-list-item-title>Raumplan</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/overview'}">
              <v-list-item-title>Wochenansicht</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/teachers'}">
              <v-list-item-title>Lehrer</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/students'}">
              <v-list-item-title>Schüler</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAdmin" :to="{path: '/settings'}">
              <v-list-item-title>Einstellungen Institut</v-list-item-title>
            </v-list-item>

            <v-list-item href="https://help.alearn.de">
              <v-list-item-title>Brauchst du Hilfe?</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/impressum'}">
              <v-list-item-title>Impressum</v-list-item-title>
            </v-list-item>

        </v-list-item-group>

        <v-list-item-group v-else
          v-model="group"
          active-class="accent">

            <v-list-item :to="{ path: '/'}">
              <v-list-item-title>Startseite</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ path: '/login'}">
              <v-list-item-title>Einloggen</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/signup'}">
              <v-list-item-title>Registrieren</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/resetpassword'}">
              <v-list-item-title>Passwort vergessen?</v-list-item-title>
            </v-list-item>

            <v-list-item href="https://help.alearn.de">
              <v-list-item-title>Brauchst du Hilfe?</v-list-item-title>
            </v-list-item>

            <v-list-item  :to="{ path: '/impressum'}">
              <v-list-item-title>Impressum</v-list-item-title>
            </v-list-item>

        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'navbar',
    data() {
        return {
          drawer: false,
          group: null,
        }
    },
    methods: {
      logout() {
        this.$store.dispatch('logout')
      }
    },
    computed: {
      ...mapState(['isLoggedIn', 'isAdmin'])
    },
    watch: {
      group () {
        this.drawer = false;
      },
    },
  }
</script>