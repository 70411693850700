import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import './assets/scss/app.scss'
import vuetify from './plugins/vuetify';


Vue.config.productionTip = false;

/**
 * @description Styles a date to the format 'weekday, dd.mm.yyyy'
 * @param date String of format 'yyyy-mm-dd'
 */
Vue.filter(
  'formatDateWithWeekday', function (date) {
      if (!date) return ''
      if(typeof date === 'string') {  //check if date is a string
        date = new Date(date);        //convert into a date object then
      }
      var weekdayArray = [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
      var weekday = weekdayArray[date.getDay()];
      let iso = date.toISOString().substr(0,10);
      return weekday + ', ' + iso.substring(8,10) + '.' + iso.substring(5,7) + '.' + iso.substring(0,4)
  }, 
)

Vue.filter(
  'formatDateWithShortWeekday', function(date){
    if (!date) return ''
    if(typeof date === 'string') {  //check if date is a string
      date = new Date(date);        //convert into a date object then
    }
    let weekdayArray = [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    let weekday = weekdayArray[date.getDay()];
    let iso = date.toISOString().substr(0,10);
    return weekday + ', ' + iso.substring(8,10) + '.' + iso.substring(5,7) + '.' + iso.substring(0,4)
  }
)

/**
 * @description Styles a date to the format 'dd.mm.yyyy'
 * @param date String of format 'yyyy-mm-dd'
 */
Vue.filter(
  'formatDate', function (date) {
      if (!date) return ''
      if(date instanceof Date) {  //check if date is a date object
        date = date.toISOString().substr(0,10)       //convert into a string of format yyyy-mm-dd then
      }
      return date.substring(8,10) + '.' + date.substring(5,7) + '.' + date.substring(0,4);
  }
)

Vue.filter(
  'formatMonth', function (monthString) {
    if (!monthString) return ''

    const year = monthString.substr(0,4);
    const month = monthString.substr(5,8);
    const monthArray = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    return monthArray[month-1] + ' ' + year;
  }
)


let app = '';


auth.onAuthStateChanged(user => {

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }

  if (user) {
    //add information of the user to store
    store.dispatch('addUserInformationToStore', user);
    
  }
})
