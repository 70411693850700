import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "login" */ '../views/Impressum.vue'),
  },
  {
    path: '/raumplan/:routeDate?/:routeRoom?',
    name: 'Raumplan',
    component: () => import(/* webpackChunkName: "login" */ '../views/Raumplan.vue'),
    props: true,
    
    // flags the routes required for a user to be authenticated
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/resetpassword',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/SignUp.vue'),
    meta: {
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/settings',
    name: 'InfoSettings',
    component: () => import(/* webpackChunkName: "infoSettings" */ '../views/InfoSettings.vue'),
    meta: {
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/students',
    name: 'Students',
    component: () => import(/* webpackChunkName: "login" */ '../views/Students.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: () => import(/* webpackChunkName: "login" */ '../views/Teachers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addevent',
    name: 'AddEvent',
    component: () => import(/* webpackChunkName: "addEvent" */ '../views/AddEvent.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editevent/:eventId',
    name: 'EditEvent',
    component: () => import(/* webpackChunkName: "editEvent" */ '../views/EditEvent.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/editseries/:seriesId',
    name: 'EditSeries',
    component: () => import(/* webpackChunkName: "editSeries" */ '../views/EditSeries.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/addstudent',
    name: 'AddStudent',
    component: () => import(/* webpackChunkName: "addStudent" */ '../views/AddStudent.vue'),
    meta: {
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/event/:routeEventId',
    name: 'InfoEvent',
    component: () => import(/* webpackChunkName: "infoEvent" */ '../views/InfoEvent.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/eventsOf/:role/:uid',
    name: 'EventsOf',
    component: () => import(/* webpackChunkName: "eventsOf" */ '../views/EventsOf.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/series/:seriesId',
    name: 'InfoSeries',
    component: () => import(/* webpackChunkName: "infoSeries" */ '../views/InfoSeries.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/teacher/:teacherId',
    name: 'InfoTeacher',
    component: () => import(/* webpackChunkName: "infoTeacher" */ '../views/InfoTeacher.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editteacher/:teacherId',
    name: 'EditTeacher',
    component: () => import(/* webpackChunkName: "editTeacher" */ '../views/EditTeacher.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/student/:studentId',
    name: 'InfoStudent',
    component: () => import(/* webpackChunkName: "infoStudent" */ '../views/InfoStudent.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editstudent/:studentId',
    name: 'EditStudent',
    component: () => import(/* webpackChunkName: "editStudent" */ '../views/EditStudent.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdminRights: true,
    }
  },
  {
    path: '/changeemail',
    name: 'ChangeEmail',
    component: () => import(/* webpackChunkName: "changeEmail" */ '../views/ChangeEmail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/overview/:routeDate?',
    name: 'Overview',
    component: () => import(/* webpackChunkName: "test" */ '../views/Overview.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lessonaccount/:studentId',
    name: 'LessonAccount',
    component: () => import(/* webpackChunkName: "lessonAccount" */ '../views/LessonAccount.vue'),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/me',
    name: 'InfoMe',
    component: () => import(/* webpackChunkName: "infoMe" */ '../views/InfoMe.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sysadmin',
    name: 'SysAdmin',
    component: () => import(/* webpackChunkName: "sysadmin" */ '../views/SysAdmin.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pagenotfound',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'),
  },
  {
    path: '/errorrole/:role?',
    name: 'ErrorRole',
    props: true,
    component: () => import(/* webpackChunkName: "errorRole" */ '../views/ErrorRole.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
    
    // flags the routes required for a user to be authenticated
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/test2',
    name: 'Test2',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test2.vue'),
    
    // flags the routes required for a user to be authenticated
    meta: {
      requiresAuth: true
    }
  },
  {
    //redirets to login for bad urls or urls that need authentication
    path: '*',
    redirect: '/pagenotfound'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

//redirection to login if user is not loged in
router.beforeEach((to,from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdminRights = to.matched.some(record => record.meta.requiresAdminRights);

  if (requiresAuth && !currentUser) {
    //if user is not logged in, but site requires login, redirect to login
    next({
      path: '/login', 
      query: { redirect: to.fullPath }
    })

  } else if(requiresAdminRights) {
    //if the page requires Admin rights, check if the logged in user is an admin
    
    auth.currentUser.getIdTokenResult().then((result) => {
      const isAdmin = result.claims.admin;
      
      if(isAdmin){
        //if the user is an admin, let him access the page
        next();
      } else {
        //if the user is not an admin, redirect him to the "you need to be an admin to see this page"-page
        next({
          path: '/errorrole/admin',
        })
      }

    }).catch((error) => {
      console.log(error);
    });

  } else {
    next();
  }
})

export default router
