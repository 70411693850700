<template>
  
    <v-app
    id="app"
    >
      <Navbar />
  
        <v-main>
          <router-view/>
        </v-main>
        
      <!-- <BottomNav v-if="isLoggedIn" /> -->
    </v-app>


  
</template>



<script>
import Navbar from './components/Navbar'
/* import BottomNav from './components/BottomNav' */
import { mapState } from 'vuex';

export default {
  name: 'app',
  components: {
    Navbar,
    /* BottomNav */
  },
  data() {
    return {
      
    }
  },
  computed: {
      ...mapState(['isLoggedIn'])
    },
};
</script>

<style>
</style>
