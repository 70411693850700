import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import de from 'vuetify/lib/locale/de'

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'de'
    },
  },
})


export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: { //gesammt: https://coolors.co/f7ea8a-70a7bd-d94d56-26d57b-4f595a-2e5766-e3f1f2-b3bcbd

                //taubenblau
                primary: { //https://coolors.co/283e47-2e5766-467283-5e8da0-70a7bd-7fb1c4-8dbacb-aaccd8-c7dfe5-e3f1f2
                    base: '#70a7bd',
                    lighten5: '#E3F1F2',
                    lighten4: '#C7DFE5',
                    lighten3: '#AACCD8',
                    lighten2: '#8DBACB',
                    lighten1: '#7FB1C4',
                    darken1: '#5E8DA0',
                    darken2: '#467283',
                    darken3: '#2E5766',
                    darken4: '#283E47',
                },

                //grau
                secondary: { //https://coolors.co/3b4142-4f595a-656f70-7b8586-a6b0b1-bdc4c5-d3d8d8-dee2e2-e9ecec-f4f6f6
                    base: '#a6b0b1',
                    lighten5: '#F4F6F6',
                    lighten4: '#E9ECEC',
                    lighten3: '#DEE2E2',
                    lighten2: '#D3D8D8',
                    lighten1: '#BDC4C5',
                    darken1: '#7B8586',
                    darken2: '#656F70',
                    darken3: '#4F595A',
                    darken4: '#3B4142',
                },

                //gelb
                accent: { //https://coolors.co/b5aa55-dacb53-ffeb51-fbeb6e-f7ea8a-f9f0a3-fbf5bc-fdfad5-ffffed-ffffef
                    base: '#f7ea8a',
                    lighten5: '#FFFFEF',
                    lighten4: '#FFFFED',
                    lighten3: '#FDFAD5',
                    lighten2: '#FBF5BC',
                    lighten1: '#F9F0A3',
                    darken1: '#FBEB6E',
                    darken2: '#FFEB51',
                    darken3: '#DACB53',
                    darken4: '#B5AA55',
                },

                //rot
                error: { //https://coolors.co/6d2f33-88353a-b34047-c6474f-d94d56-de646c-e37a81-eca6ab-f6d3d5-fdf4f5
                    base: '#D94D56',
                    lighten5: '#FDF4F5',
                    lighten4: '#F6D3D5',
                    lighten3: '#ECA6AB',
                    lighten2: '#E37A81',
                    lighten1: '#DE646C',
                    darken1: '#C6474F',
                    darken2: '#B34047',
                    darken3: '#88353A',
                    darken4: '#6D2F33',
                },

                //grün
                success: { //https://coolors.co/2e5e46-2c7c54-2a9a61-28b86e-26d57b-42db8c-64e2a1-9aecc2-b5f1d2-cdf6e1
                    base: '#26D57B',
                    lighten5: '#CDF6E1',
                    lighten4: '#B5F1D2',
                    lighten3: '#9AECC2',
                    lighten2: '#64E2A1',
                    lighten1: '#42DB8C',
                    darken1: '#28B86E',
                    darken2: '#2A9A61',
                    darken3: '#2C7C54',
                    darken4: '#2E5E46',
                },


                //background: colors.white,
                //info: '',
                //warning: '',
                //anchor: '',
            }
        }
    },

    //add german language packages. You can also add own translations. See https://vuetifyjs.com/en/features/internationalization/#creating-a-translation
    lang: {
      locales: { de },
      current: 'de',
    },
});
