import { auth, institutesCollection, studentsCollection, teachersCollection } from '@/firebase';

/**
* @description gets the instiute settings (opening Time, rooms etc) from firestore and setts this.rooms
*/
async function getInsituteSettings(){
  let settings = {};

  //get instituteId from currently logged in user
  let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId; //needed to add data in other funtions (eg addNewSchoolfreeDay)

  //get institute settings
  try {
    const doc = await institutesCollection.doc(instituteId).get();
    settings = doc.data()
  } catch {
    throw "Could not find this doument in firestore";
  }
  
  return settings;  
}

async function getPersonsFirstName(personRef) {
  let firstname = '';
  const persondoc = await personRef.get();
  if (persondoc.exists) {
    firstname = persondoc.data().firstname;
  } else { console.log('No such document') }
  return firstname;
}

async function getPersonsLastName(personRef) {
  let lastname = '';
  const persondoc = await personRef.get();
  if (persondoc.exists) {
    lastname = persondoc.data().lastname;
  } else { console.log('No such document') }
  return lastname;
}

/* not used anywhere yet */
async function getPersonsName(personRef) {
  let firstname = '';
  let lastname = '';
  const persondoc = await personRef.get();
  if (persondoc.exists) {
    firstname = persondoc.data().firstname;
    lastname = persondoc.data().lastname;
  } else { console.log('No such document') }
  return firstname + ' ' + lastname;
}

async function getStudent(studentId) {
  let student = {};

  try {
    const studentDoc = await studentsCollection.doc(studentId).get();
    student = studentDoc.data();
    student.id = studentDoc.id;
  } catch(error) { 
    throw "Could not find this doument in firestore" + error;
  }
  return student
}

async function getTeacher(teacherId) {
  let teacher = {};

  try {
    const teacherDoc = await teachersCollection.doc(teacherId).get();
    teacher = teacherDoc.data();
    teacher.id = teacherDoc.id;
  } catch(error) { 
    throw "Could not find this doument in firestore" + error;
  }
  return teacher
}



export {
  getInsituteSettings,
  getPersonsFirstName,
  getPersonsLastName,
  getStudent,
  getTeacher,
}